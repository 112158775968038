$product-grid-height: calc(100vh - (#{$header-height} + #{$dash-board-header-height}) - 15px);

.product-page{
  @include setFlex(column, right, end);
}
.product-grid{
  max-height: $product-grid-height;
  td{
    text-align: center;
  }
  th{
    justify-content: center;
  }
}
.product-actions-cell, .product-version-cell{
  @include setFlex(column);
}
.grid-cell-form{
  box-sizing: border-box;
  height: 90px;
  .k-form-error{
    position: absolute;
  }
}