.email-confirmation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40vh;
    text-align: center;
}

.email-confirmation:first-child {
    align-self: center;
    justify-content: center;
}
