$button-bar-height: 56px;
$versions-grid-height: calc(100vh - (#{$header-height} + #{$dash-board-header-height} + #{$button-bar-height}) - 25px);

.version-info-section{
  @include setFlex(column, start, end);
  gap: 1px;
  height: $button-bar-height;
  margin-bottom: 10px;
}
.version-grid{
  max-height: $versions-grid-height;
  .action-cell{
    @include setFlex(column);
    & >*{
      max-width: 120px;
    }
  }
}