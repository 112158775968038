
.sign-in-form{
  @include fitSize(height, 98vh, $header-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  form{
    width: 40%;
  }
}