.input-error{
  color: red;
}

.upload-component{
  @include setFlex(column, center, flex-start);
  .chosen-files-container{
    @include setFlex(row, flex-start);
    white-space: nowrap;
    overflow-x: auto;
    font-size: 13px;
  }
}

.modal-wapper .k-dialog-buttongroup {
  border: none;
}

.modal-wapper .k-window-titlebar {
  border: none;
}

.modal-wrapper-var {
    font-weight: 600;
}