
.header {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fffefe;
  height: $header-height;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);

  .header-left{
    @include setFlex(row);
    gap: 40px;
    cursor: pointer;
  }
  .logo-icon{
    font-size: 20px;
    font-weight: 500;
      img {
        height: 38px;
        margin-left: 10px;
      }
  }
  .nav-icon{
    color: $std-btn-color;
    font-size: 30px;
  }
}
