.order-grid {
    max-height: $product-grid-height;

    td {
        text-align: center;
    }

    th {
        justify-content: center;
    }
}

.filter-row-left {
    @include setFlex(row, start);
    gap: 10px;
    margin-left: inherit;

    .k-flex {
        margin-bottom: 24px;
    }

    .date-buttons {
        @include setFlex(row, start);
        gap: 10px;
        margin-left: auto;
    }

    .k-input-values {
        display: flex;
        justify-content: center;
    }
}

.filter-row-right {
    @include setFlex(row, end);
}

.filter-row {
    @include setFlex(row, space-between);
    height: 40px;
    margin-bottom: 10px;
}

