$product-grid-height: calc(100vh - (#{$header-height} + #{$dash-board-header-height}) - 15px);

.page-statistics {
    .k-grid-toolbar {
        justify-content: start;
    }

    .page-statistics-header {
        @include setFlex(row, start, end);
    }

    .page-statistics-dropdown {
        max-width: 600px;
    }

    max-height: $product-grid-height;

    td {
        text-align: left;
        vertical-align: middle;
    }

    th {
        font-weight: 600;
        text-align: center;
        justify-content: left;
        vertical-align: middle;
    }
}

.table {
    table-layout: auto;
    max-width: 600px;
}