$dash-board-header-height: 85px;

.dash-board-header{
  @include setFlex(row, space-between);
  padding: 5px;
  height: $dash-board-header-height;
}
.dash-board-body{
  display: flex;
  padding: 5px;
}
.dash-board-navigation{
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  & > *{
    margin-bottom: 1px;
  }
}
.dash-board-content{
  @include setFlex(column, start, stretch);
  flex-grow: 8;
  padding: 0 0 5px 5px;
}