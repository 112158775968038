$version-info-height: 178px;
$version-info-button-bar-height: 62px;
$version-grid-height: calc(100vh - (#{$header-height} + #{$dash-board-header-height} + #{$version-info-height} + #{$version-info-button-bar-height}) - 45px);

.version-page{
  @include setFlex(column, center, stretch);
  &>:not(:nth-child(1)){
    margin-top: 15px;
  }
}
.version-info{
  height: $version-info-height;
  background-color: $form-background-color;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;

  .version-info-body{
    @include setFlex(row, start, start);
    gap: 5rem;
  }
  .body-row{
    @include setFlex(row, start, start);

    span{
      font-weight: 500;
    }
    div{
      margin-left: 5px;
    }
  }
  .version-info-spinner{
    background-color: inherit;
  }
}
.version-info-button-bar{
  @include setFlex(row, space-between, center);
  height: $version-info-button-bar-height;
  overflow: auto;
  padding-right: 1rem;
}

.version-info-button-bar-box {
  @include setFlex(row, start, center);
  gap: 10px;	
}

.version-info-dropdown-option-box {
  @include setFlex(row, end, center);
  padding-right: 1rem;
  margin-top: 0;
}

.configurations-grid{
  max-height: $version-grid-height;
}

.td-name-cell {
  position:relative;
}

.td-name-checkbox {
  position: absolute;
  top: 0;
  right: 0.5rem;
  .k-checkbox {
    border-color: #000000;
    border-radius: 2px;
  }

  .k-checkbox:checked {
    border-color: #0d6efd;
    border-radius: 2px;
  }
}

.version-info-dropdown-popup {
  margin-top: 0.5rem;
}

.configurations-grid col:nth-of-type(4) {
    width: 50%
}

.product-grid {
    .changed-params-row {
        div {
            text-align: start;
            max-height: 8vh;
            overflow-y: auto;
        }
    }
}
