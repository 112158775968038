$header-height: 48px;

$config-page-button-bar-height: 59px;
$config-page-header-height: 20px;
$config-page-description-height: 210px;
$config-parameters-container-height: calc(
  100vh -
    (
      #{$header-height} + #{$config-page-header-height} + #{$config-page-description-height} +
        2 * #{$config-page-button-bar-height} + 220px
    )
);

.page-body {
  .page-body-configuration {
    height: calc(100vh - (#{$header-height}));
    overflow-y: auto;
  }
}

.config-parameters-container {
  height: $config-parameters-container-height;
}
