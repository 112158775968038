.selected-tariff-button {
    background-color: forestgreen;
}

.selected-tariff-button:hover {
    background-color: darkgreen;
}

.billing-grid {
    max-height: $product-grid-height;

    td {
        text-align: center;
    }

    th {
        justify-content: center;
    }
}

.create-tariff-form {
    .modal-body {
        overflow: auto;
        padding: 15px;
        max-height: 65vh;
        width: 40vw;
    }
}