@import 'organisationPage/index';
@import 'dashboardPage';
@import 'productPage';
@import 'productVersionsPage';
@import 'productVersionPage';
@import 'productConfigurationPage';
@import 'manageAccountPage';
@import 'configurationRequestsPage';
@import 'settingsPage';
@import 'usersPage';
@import 'statisticPage';
@import 'publicationPage';
@import 'billingPage';
@import 'emailConfirmationPage';
@import 'securityPage';
@import 'appBundlePage';
@import 'appBundleVersionPage';
@import 'appBundleAliasPage';
@import 'orderPage';

.page-body {
  height: calc(100vh - (#{$header-height}));
  overflow-y: auto;
}
