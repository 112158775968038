$padding: 0.5rem;
$padding-small: 0.25rem;

.configuration-requests-header {
  @include setFlex(row, end, center);
  margin-bottom: 20px;
}

.column-first {
  @include setFlex(row, center, center);
  flex: 0 1 5%;
  padding: $padding;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.column-parameteres-changes { 
  flex: 0 1 50%;
  height: 100%;
}

.column-date { 
  flex: 0 1 15%;
  padding: $padding;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.column-name {
  flex: 0 1 15%;
  padding: $padding;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.column-actions {
  flex: 0 1 15%;
  padding: $padding;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.configuration-requests-header-table {
  @include setFlex(row, start, center);
  border: 0.5px solid #dee2e6;

  .column-first {
    border: 0.5px solid #dee2e6;
    border-bottom: none;
  }

  .column-parameteres-changes {
    @include setFlex(row, center, center);
    border: 0.5px solid #dee2e6;
    border-bottom: none;
  }

  .column-name {
    @include setFlex(row, center, center);
    border: 0.5px solid #dee2e6;
    border-bottom: none;
  }

  .column-date {
    @include setFlex(row, center, center);
    border: 0.5px solid #dee2e6;
    border-bottom: none;
  }

  .column-actions {
    @include setFlex(row, center, center);
    border: 0.5px solid #dee2e6;
    border-bottom: none;
  }
}

.row-table {
  @include setFlex(row, start, end);
  height: 100%;
  transition: all 0.3 ease;
  &:hover {
    background-color: #e9ecef;
    transition: all 0.3 ease;
  }

  .column-first {
    border-left: 0.5px solid #dee2e6;
    border-right: 0.5px solid #dee2e6;
    border-top: none;
    border-bottom: none;
  }

  .column-parameteres-changes {
    border-left: 0.5px solid #dee2e6;
    border-right: 0.5px solid #dee2e6;
    border-top: none;
    border-bottom: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .parameteres-changes {
      padding: $padding-small;
      margin-top: $padding;
    }

    .parameteres-changes-name {
      font-weight: 500;
    }
  }

  .column-name {
    border: 0.5px solid #dee2e6;
    border-bottom: none;
    border-top: none;
  }

  .column-date {
    border: 0.5px solid #dee2e6;
    border-bottom: none;
    border-top: none;
  }

  .column-actions {
    border: 0.5px solid #dee2e6;
    border-bottom: none;
    border-top: none;
  }
}
.no-changes-parameters {
  @include setFlex(row, center, center);
}

.tooltip > .tooltip-inner {
  max-width: 100%;
  text-align: left;
  word-wrap: pre-wrap;
  line-height: 30px;
}

.tooltip-title {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.tooltip-params {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.filter-row-left {
    @include setFlex(row, start);
    gap: 10px;
    margin-left: inherit;

    .k-flex {
        margin-bottom: 24px;
    }

    .date-buttons {
        @include setFlex(row, start);
        gap: 10px;
        margin-left: auto;
    }

    .k-input-values {
        display: flex;
        justify-content: center;
    }
}

.filter-row-right {
    @include setFlex(row, end);
}

.filter-row {
    @include setFlex(row, space-between);
    height: 40px;
    margin-bottom: 10px;
}

.action-buttons-group {
    @include setFlex(row, center);
    gap: 10px;
    margin-left: inherit;
}

.status-filter-multiselect {
    @include setFlex(row, end, center);
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    min-width: 200px;
}